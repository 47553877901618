<template>
  <div class="special-ico" v-if="goodsInfo">
    <Header titleurl="special" v-if="fromoa != '1'" ref="headerchild" />
    <!-- <div class="section_head">{{goodsInfo.companyName}}</div> -->
    <div class="content">
      <div class="wrap-box">
        <div class="section left-700">
          <div class="goods-box ">
            <div class="picbox">
              <magnifier :imgList="slidesimages"></magnifier>
            </div>
            <div class="descbox">
              <div class="thing_name">{{ goodsInfo.title }}</div>
              <div class="thing_konwn"></div>
              <div
                class="thing_place"
                :class="
                  goodsInfo.isServiceGoods == 1 && goodsInfo.prepayAmount == 0
                    ? 'isting'
                    : ''
                "
              >
                <div class="thing_every" v-if="goodsInfo.isServiceGoods != 1">
                  <span class="font_grey">价格</span>
                  <span class="thing_newprice"
                    ><span>¥</span><span>{{ goodsInfo.price }}</span></span
                  >
                  <img
                    class="hotshop"
                    :src="require('../../assets/img/hotshop.png')"
                  />
                </div>
                <div
                  class="thing_every  ting-tit"
                  style="padding-left:10px;"
                  v-if="goodsInfo.isServiceGoods == 1"
                >
                  <span class=" thing_pay_tit">计费方式</span>
                  <span
                    slot="reference thing_pay_tit"
                    style="font-size:14px;cursor: pointer;"
                    >（{{ goodsInfo.billingMode }}）</span
                  >
                  <span class="thing_newprice thing_pay_tit"
                    ><span>{{ goodsInfo.billingVal }}</span>
                  </span>
                </div>
                <div
                  class="thing_every ting-price"
                  v-if="goodsInfo.isServiceGoods == 1"
                >
                  <span
                    class="thing_pay"
                    style="padding-left:10px;"
                    v-if="goodsInfo.prepayAmount > 0"
                  >
                    <span class="thing_pay_tit">预付款：</span>
                    <span class="thing_pay_result">
                      <span style="color:#f00" class="realpay"
                        >￥{{ goodsInfo.prepayAmount }}（线上价）</span
                      >
                      <span style="color:#f00" class="realpay"
                        >￥{{ goodsInfo.prepayAmount }}（到店价）</span
                      ></span
                    >
                    <div class="clearfix"></div>
                  </span>
                </div>
              </div>
              <div
                class="thing_margin"
                v-if="
                  goodsInfo.isServiceGoods == 1 &&
                    goodsInfo.billingMode == '比例计费'
                "
              >
                <el-table :data="goodsInfo.billingLadder">
                  <el-table-column label="计价范围" width="150">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.ladderStart + '-'
                      }}</span>
                      <span>{{
                        scope.row.isNoLimit == 1 ? '无限' : scope.row.ladderEnd
                      }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="费率" width="100">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.ladderValue + '%'
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                class="thing_sale thing_margin"
                v-if="goodsInfo.isNeedStock == 1"
              >
                <span class="font_grey">月销量</span>
                <span class="font_red">{{ goodsInfo.saleCount }}</span>
              </div>
              <div
                class="thing_margin"
                style="margin-top:5px;"
                v-if="goodsInfo.isNeedStock == 1"
              >
                <span class="font_grey">数量</span>
                <span class="font_grey">
                  <el-input-number
                    :min="1"
                    :max="goodsInfo.stock"
                    @change="handleChange"
                    v-model="buynum"
                    controls-position="right"
                    placeholder=""
                  />
                  件
                </span>
                <span class="font_grey">库存{{ goodsInfo.stock }}件</span>
              </div>

              <div class="thing_margin" style="margin:30px 0px 30px 10px">
                <el-button
                  class="btnbuy"
                  @click="ontobuy(goodsInfo)"
                  :class="
                    goodsInfo.stock == 0 || goodsInfo.stock < 0
                      ? 'disabled'
                      : ''
                  "
                  v-if="goodsInfo.isNeedStock == 1"
                >
                  {{
                    goodsInfo.stock == 0 || goodsInfo.stock < 0
                      ? '库存不足'
                      : '立即购买'
                  }}</el-button
                >

                <el-button
                  class="btnbuy"
                  @click="ontobuy"
                  v-if="goodsInfo.isNeedStock != 1"
                >
                  立即购买</el-button
                >
              </div>
              <div class="thing_margin margin-right">
                <span class="font_grey">服务承诺</span>
                <span class="font-dimgrey">破损包退</span>
                <span class="font-dimgrey">正品保障</span>
                <span class="font-dimgrey">急速退款</span>
                <span class="font-dimgrey">赠运费险</span>
              </div>
              <div></div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="detail-box" v-if="false">
            <div class="content-left">
              <div class="content_left_first" id="content_left_html">
                <span class="content_store_name">
                  <router-link
                    target="_blank"
                    :to="{
                      path: '/company/detail',
                      query: { id: goodsInfo.companyId }
                    }"
                  >
                    {{ goodsInfo.companyName }}
                  </router-link>
                </span>
                <!-- <div class="content_grade">6</div> -->
                <span class="store-name"></span>
                <div class="content_mfw">
                  <span>描述</span>
                  <span>服务</span>
                  <span>物流</span>
                </div>
                <div class="content_fs">
                  <span>4.9↑</span>
                  <span>4.9↑</span>
                  <span>4.9↑</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-left left-480">
          <div class="content_left_first" id="content_left_html">
            <span class="content_store_name">
              <router-link
                target="_blank"
                :to="{
                  path: '/company/detail',
                  query: { id: goodsInfo.companyId }
                }"
              >
                {{ goodsInfo.companyName }}
              </router-link>
            </span>
            <div class="content_hr"></div>
            <div class="content_score">
              <!-- <div class="content_grade">6</div> -->
              <span class="store-name"></span>
              <div class="content_fs">
                <span>
                  <i class="el-icon-success success"></i> 描述<span>4.9↑</span>
                </span>
                <span>
                  <i class="el-icon-success success"></i> 服务<span> 4.9↑</span>
                </span>
                <span>
                  <i class="el-icon-success success"></i> 物流<span> 4.9↑</span>
                </span>
              </div>
            </div>
            <div class="wcon" v-if="cmpinfo != null">
              <div class="contact">
                <span
                  class="contact_tel"
                  style="display:inline-block;width:100%"
                >
                  联系电话：{{ cmpinfo.companyTel }}</span
                >

                <span
                  class="contact_add"
                  style="display:inline-block;width:100%"
                  >联系地址：{{ cmpinfo.address }}</span
                >
              </div>
              <div id="contactamaps">
                <el-amap
                  vid="amapcontactamaps"
                  :center="center"
                  :zoom="zoom"
                  class="amap-demo"
                >
                  <el-amap-marker
                    v-for="(marker, index) in markers"
                    :position="marker"
                    :key="index"
                  ></el-amap-marker>
                </el-amap>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right detail">
        <ul class="myTabs nav nav-tabs">
          <li class="active">
            <a href="#shopdetail" data-toggle="tab">商品详情</a>
          </li>
          <li class="dropdown" style="display:none;">
            <a
              href="#"
              id="myTabDrop1"
              class="dropdown-toggle"
              data-toggle="dropdown"
            >
              手机购买
              <b class="caret"></b>
            </a>
            <ul class="dropdown-menu" role="menu" aria-labelledby="myTabDrop1">
              <li>
                <a href="#wechat" tabindex="-1" data-toggle="tab">微信支付</a>
              </li>
              <li>
                <a href="#alipay" tabindex="-1" data-toggle="tab">支付宝支付</a>
              </li>
            </ul>
          </li>
        </ul>
        <div id="mycontent" class="tab-content">
          <div class="tab-pane fade in active">
            <div class="shopnumber">
              <div style="margin-bottom: 10px;"></div>
              <div class="margin-bottom: 15px;">
                <span style="font-size: 10px;font-weight: 700;color:#8B8682;"
                  >产品参数:</span
                >
              </div>
              <div class="content_tab">
                <div class="content_tab_left">
                  <div
                    class="content_tab_bottom"
                    v-for="(item, key) in goodsInfo.productAttrs"
                    :key="key"
                  >
                    <span>{{ item.name }}：</span
                    ><span :title="item.value">{{ item.value }}</span>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div v-html="goodsInfo.content" class="contentbox"></div>
      </div>
      <div class="clearfix"></div>
      <div class="left-480 commond">
        <div class="font-grey commond-title">
          店铺推荐
        </div>
        <a
          :href="'/product/detail?uniqueNo=' + item.uniqueNo"
          v-for="(item, i) in productlist"
          target="_blank"
          :key="i"
        >
          <div class="item-img">
            <el-image :src="item.headImg" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div class="product-title">
              {{ item.title }}
            </div>
            <div class="product-price">
              <template v-if="item.billingMode != ''">
                <span class="rmb">
                  {{ item.billingMode }}
                </span>
                {{ item.billingVal }}
              </template>
              <template v-else>
                <span class="rmb">¥</span>{{ item.price }}
              </template>
            </div>
          </div>
        </a>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="clearfix"></div>
    <Footer v-if="fromoa != '1'" />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import magnifier from '@/components/magnifier.vue';
import {
  productgetproductbyuniqueno,
  productgetspecialproductlist
} from '@/api/product';
import { companygetcompanyinfo } from '@/api/company.js';
import Cookies from 'js-cookie';
import { getCookieByName } from '@/utils/helper';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    magnifier
  },
  data() {
    return {
      fromoa: '',
      center: [113.768678, 34.767936],
      markers: [[113.768678, 34.767936]],
      zoom: 15,
      isamap: true,
      slidesimages: [],
      zoomerOptions: {
        zoomFactor: 3,
        pane: 'pane',
        hoverDelay: 300,
        namespace: 'zoomer-bottom',
        move_by_click: false,
        scroll_items: 4,
        choosed_thumb_border_color: '#dd2c00',
        scroller_position: 'bottom',
        zoomer_pane_position: 'right'
      },
      firstShow: true,
      show: false,
      height: '600',
      productlist: [],
      goodsInfo: null,
      hotList: [],
      buyCount: '',
      imgList: [],
      imgurl: '',
      currindex: 0,
      isSelected: true,
      buynum: 1,
      price: 0,
      uniqueNo: '',
      cmpinfo: null,
      cid: 0
    };
  },
  methods: {
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then(res => {
        this.cmpinfo = res.data.result;
        // for (var i = 0; i < res.data.result.comHonorList.length; i++) {
        //   this.comHonorList.push(res.data.result.comHonorList[i].imgOUrl);
        // }
        // for (var i = 0; i < res.data.result.comElegantList.length; i++) {
        //   this.comElegantList.push(res.data.result.comElegantList[i].imgOUrl);
        // }
        for (var i = 0; i < res.data.result.comCertList.length; i++) {
          this.comCertList.push(res.data.result.comCertList[i].imgOUrl);
        }

        this.comTeamHonorList = this.cmpinfo.comTeamHonorList;
        this.comElegantList = this.cmpinfo.comElegantList;
        if (
          this.cmpinfo.lnglatAmap != null &&
          this.cmpinfo.lnglatAmap.length > 0
        ) {
          this.center = this.cmpinfo.lnglatAmap.split(',');
          this.markers = [this.center];
        } else {
          this.isamap = false;
        }
      });
    },
    handleChange(value) {
      this.buynum = value;
    },
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    onimgpreview(imgurl, index) {
      this.currindex = index;
      console.log(this.currindex);
      this.imgurl = imgurl;
    },
    onproductgetproductbyuniqueno() {
      var that = this;
      productgetproductbyuniqueno({ uniqueno: that.uniqueNo }).then(res => {
        if (res.data.code === 1) {
          this.goodsInfo = res.data.result;
          this.imgList = res.data.result.picList;
          this.price = res.data.result.price;
          for (let i = 0; i < this.imgList.length; i++) {
            let img = {
              url: this.imgList[i],
              bigurl: this.imgList[i],
              id: i
            };
            this.slidesimages.push(img);
          }
          //this.uniqueno=res.data.result.uniqueno;
          this.imgurl = this.imgList[0];
          this.cid = this.goodsInfo.companyId;
          this.oncompanygetcompanyinfo();
        } else {
          this.$message({
            type: 'warning',
            message: '未找到商品'
          });
        }
      });
    },
    onproductgetspecialproductlist() {
      var that = this;
      productgetspecialproductlist({ count: 4 }).then(res => {
        if (res.data.code === 1) {
          that.productlist = res.data.result;
        }
      });
    },
    ontobuy(item) {
      if (item.isNeedStock == 1) {
        if (this.goodsInfo.stock == 0) {
          this.$message({
            type: 'warning',
            message: '库存不足'
          });
          return;
        }
      } else if (item.isNeedStock == 0) {
        this.buynum = 1;
      }
      let accessToken = getCookieByName('access_token');
      if (!accessToken) {
        this.$refs.headerchild.dialogVisible = true;
        this.$refs.headerchild.onappCodeRefreshButton();
        return;
      }
      this.$router.push({
        path: '/shopping/getordercommon',
        query: {
          fromoa: this.fromoa,
          uniqueNo: this.uniqueNo,
          buynum: this.buynum,
          price: this.price
        }
      });
    }
  },
  mounted() {
    screenLeft;
  },
  created() {
    this.uniqueNo = this.$route.query.uniqueNo;
    this.fromoa = this.$route.query.fromoa;
    this.onproductgetproductbyuniqueno();
    this.onproductgetspecialproductlist();
  }
};
</script>
<style scoped>
body {
  background-color: #eaecef;
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.special-ico {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
  background-color: #efefef;
}
.content {
  width: 100%;
  margin-top: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 755px;
}

.special-ico .clearfix {
  clear: both;
}

.container_special {
  margin: 20px auto;
}
.container_row {
  margin: 0px auto;
}
.left-480 {
  width: 480px;
  background-color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}
.left-480 {
  width: 220px;
  height: 303px;
  background-color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}

.container_row_img {
  cursor: pointer;
}
.left-480:nth-child(5),
.left-480:nth-child(10) {
  margin-right: 0px !important;
}
.container_row_name {
  color: #666;
  height: 35px;
  font-size: 12px;
  width: 100%; /* margin-top: -4px; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 40px;
}
.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_price {
  font-size: 20px;
  color: #fff;
  width: 150px;
}
.container_row_price .rmb {
  font-size: 12px;
  color: #fff;
}
.left-480 {
  height: auto;
}
.container_row_price .mode {
  font-size: 12px;
  color: #666;
}

.product-zoomer {
  width: 400px;
}
.content .wrap-box {
  width: 1200px;
}
.content .wrap-box .left-700 {
  width: 700px;
  float: left;
}
.content .wrap-box .left-480 {
  width: 490px;
  margin-right: 0;
}
.content .wrap-box .goods-box {
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
}
.content .wrap-box .pic-box {
  width: 400px;
  float: left;
  height: 200px;
}
.content .wrap-box .goods-spec {
  width: 350px;
  float: left;
}
.section_head {
  background: #ff292e;
  font-size: 30px;
  height: 88px;
  line-height: 88px;
  text-align: center;
  color: #fff;
}
.section_detail {
  width: 800px;
}
.picbox {
  float: left;
  width: 300px;
  /* height: 550px; */
}
.picbox ul li {
  width: 50px;
  height: 50px;
  border: 2px solid transparent;
}
.picbox ul li.current {
  border: 2px solid red;
}

.picbox ul {
  overflow: hidden;
  width: 431px;
  overflow: auto;
}
.descbox {
  margin-left: 20px;
  float: left;
  width: 360px;
  min-height: 480px;
}
.left-480 {
  height: 480px;
  float: right;
}
.picbox > ul > li {
  float: left;
  margin-right: 18px;
}
.hr {
  margin: 15px 0;
  border-bottom: 1px solid #dcdcdc;
}
.thing_name {
  padding-left: 10px;
  font-size: 20px;
  font-weight: 600;
  word-break: break-all;
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 3; /**显示的行数**/
  overflow: hidden;
}
.thing_konwn {
  padding-left: 15px;
  font-size: 14px;
  color: #ff4068;
  margin-bottom: 15px;
}
.thing_place {
  background-image: url(../../assets/img/priceback.png);
  background-repeat: no-repeat;
  height: 78px;
  line-height: 50px;
  background-size: cover;
}
.thing_place.isting {
  height: 50px;
  line-height: 50px;
}
.thing_place.isting .thing_every.ting-tit {
  height: 50px;
  line-height: 50px;
}
.thing_every {
  padding-top: 4px;
}
.thing_every.ting-tit {
  height: 28px;
  line-height: 28px;
  padding-top: 0;
}
.thing_every.ting-price {
  min-height: 20px;
  line-height: 20px;
  padding-left: 10px;
}
.font_grey {
  margin-left: 10px;
  color: #8b8682;
  font-size: 10px;
}
.thing_oldprice {
  margin-left: 50px;
  font-size: 14px;
}
.thing_newprice {
  margin-left: 35px;
  font-size: 28px;
  color: red;
  font-weight: 700;
}
.thing_pay {
  font-size: 15px;
}
.thing_pay .realpay {
  font-weight: 600;
}
.thing_every .thing_pay_tit {
  float: left;
  display: block;
  font-size: 14px;
}

.thing_every .thing_pay_result {
  float: left;
  display: block;
}
.thing_every .thing_pay_result .realpay {
  float: left;
  display: block;
  width: 100%;
}

.hotshop {
  margin-bottom: 12px;
}
.thing_margin {
}
.thing_margin .el-input-number {
  width: 80px;
}
.thing_margin >>> .el-input-number .el-input-number__increase,
.thing_margin >>> .el-input-number .el-input-number__decrease {
  width: 20px;
}
.thing_margin .btnbuy {
  border-radius: 0;
  width: 180px;
  background-color: #ffeded;
  border-color: #0a36b9;
  color: #fff;
  font-weight: 1000;
  font-size: 17px;
  background-color: #0a36b9;
}
.thing_margin .btnbuy.disabled {
  cursor: unset;
  background-color: #bebebe;
  border-color: #bebebe;
}

.thing_sale {
  height: 30px;
  border-bottom: 1px #bebebe dotted;
  border-top: 1px #bebebe dotted;
  padding: 10px 20px;
  line-height: 30px;
  padding-left: 0px;
  margin-left: 0px;
}
.thing_sale .font_grey {
  margin-left: 10px;
}
.font_red {
  color: red;
  font-weight: 600;
  margin-left: 5px;
}
.right_left {
  color: #bebebe;
  margin: 0 30px;
  font-size: 16px;
}
.number {
  margin-left: 50px;
  width: 45px;
  height: 30px;
}
.number_up {
  border: 1px solid #bebebe;
  margin-top: -20px;
  width: 15px;
  height: 13px;
  cursor: pointer;
}
.number_down {
  border: 1px solid #bebebe;
  margin-left: -19px;
  margin-top: 13px;
  width: 15px;
  height: 13px;
  cursor: pointer;
}
.font-dimgrey {
  color: #708090;
  font-size: 10px;
}
.font-grey {
  color: #8b8682;
  font-size: 10px;
}
.margin-right > span {
  margin-right: 20px;
}
.right_img {
  margin-bottom: 10px;
  position: relative;
}
.right_name {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left-700 {
  width: 700px;
}
.left-480 {
  width: 220px;
}

.content-left {
  width: 200px;
  float: left;
  margin-right: 10px;
  margin-left: 10px;
}
.content-right {
  width: 705px;
  float: left;
}
.content_left_first {
  height: 200px;
  padding: 10px 0;
}
.content_store_name {
  margin-left: 13px;
  font-size: 12px;
  font-weight: 700;
}
.content_store_name a {
  font-size: 18px;
  font-weight: 600;
  border-left: 4px solid #0a36b9;
  padding-left: 5px;
}

.content_hr {
  margin: 10px 0;
  /* border-bottom: 1px solid #bebebe; */
}
.content_score {
  border-top: 1px solid #bebebe;
  border-bottom: 1px solid #bebebe;
  padding: 5px 0;
  font-size: 12px;
}
.content_grade {
  margin-left: 40px;
  width: 20px;
  background-color: orangered;
  color: yellow;
  height: 20px;
  text-align: center;
  float: left;
}
.content_mfw {
  font-size: 14px;
  letter-spacing: 4px;
}
.content_fs {
  color: red;
  font-size: 12px;
  margin: 3px 0 0 40px;
  letter-spacing: 1px;
}
.content_fs .success {
  color: #67c23a;
}
.content_fs span {
  margin-right: 10px;
}
.myTabs > .active > a {
  color: #fff;
  border-top: 1px solid #0a36b9;
  background-color: #0a36b9;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 38px;
}
.nav > li {
  position: relative;
  display: block;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  position: relative;
  display: block;
  padding: 10px 15px;
  background: #fff;
  border-bottom: none;
}
.content-right .shopnumber {
  height: 380px;
  border: 1px solid #ddd;
  padding: 5px 15px;
  height: auto;
  padding-bottom: 20px;
}
.dropdown {
  margin-left: 400px;
}
.content_tab_bottom {
  margin-top: 10px;
  float: left;
  width: 31%;
  overflow: hidden;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content_tab_bottom span {
  color: #8b8682;
  font-size: 10px;
}
.container_row_price {
  position: absolute;
  bottom: 18px;
  background: rgba(0, 0, 0, 0.5019607843137255);
  padding: 1px 0;
}
.container_row_price span {
  display: inline-block;

  width: 100%;
  margin: 0 auto;
  text-align: center;
  text-align: left;
  font-size: 12px;
}

.left-480 .company-logo .el-image {
  width: 160px;
  height: 160px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.left-480 .company-logo >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}

.left-480 .item-img {
  width: 160px;
  height: 185px;
  text-align: center;
  margin: 18px 5px;
  float: left;
}
.left-480 .item-img .el-image {
  width: 160px;
  height: 160px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.left-480 .item-img >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}

.left-480 .company-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.left-480 .product-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.left-480 .product-price {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ff1100;
}
.el-table {
  font-size: 12px;
}
#contactamaps {
  margin: 10px;
  width: 98%;
  height: 300px;
  margin-left: 0px;
  float: left;
  margin-left: 1%;
}
.contact {
  padding: 10px;
  font-size: 14px;
}
.detail {
  background: #ffffff;
  padding: 10px;
  width: 1180px;
  margin-top: 10px;
}
.commond {
  width: 1180px;
  height: auto;
  margin-top: 10px;
  padding: 10px;
  float: unset;
}
.commond .commond-title {
  font-size: 15px;
  font-weight: 600;
  padding-left: 3px;
  color: #222;
}
</style>
